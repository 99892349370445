const getBusinessSegment = (businessType) => {
  let segment;
  if (businessType && businessType !== 'other') {
    segment = businessType;
  } else {
    segment = 'business';
  }
  return segment;
};

const getSegment = (businessType, userType) => {
  let segment;
  switch (userType) {
    case 'consumer':
      segment = 'personal';
      break;
    case 'business':
      segment = getBusinessSegment(businessType);
      break;
    case 'other':
      segment = 'other';
      break;
    default:
      segment = '';
  }
  return segment.charAt(0).toUpperCase() + segment.slice(1);
};

const getPrimaryUse = (businessType, userType) => {
  let primaryUse;

  switch (userType) {
    case 'consumer':
      primaryUse = 'personal';
      break;
    case 'business':
      primaryUse = getBusinessSegment(businessType);
      break;
    default:
      primaryUse = 'none';
  }
  return primaryUse;
};

const getSecondaryUse = (demographics) => {
  if (!demographics) {
    return '';
  }

  const secondaryUseArray = [];

  if (demographics.additional_use_photography) {
    secondaryUseArray.push('photography');
  }
  if (demographics.additional_use_personal) {
    secondaryUseArray.push('consumer');
  }
  if (demographics.additional_use_business) {
    secondaryUseArray.push('business');
  }
  if (demographics.additional_use_none) {
    secondaryUseArray.push('none');
  }
  return secondaryUseArray.join(',');
};

/**
 * Returns user segment, primary use, and secondary use for tracking purposes
 *
 * @param {Object} demographics Demographics object
 *
 * @returns {Object} Demographic info
 */
export default function getDemographicInfo(demographics) {
  const businessType = demographics && demographics.business_type;
  const userType = demographics && demographics.user_type;
  const currentUserPrimaryUse = getPrimaryUse(businessType, userType);
  const currentUserSecondaryUse = getSecondaryUse(demographics);
  const segment = getSegment(businessType, userType);

  return {
    currentUserPrimaryUse,
    currentUserSecondaryUse,
    segment
  };
}
