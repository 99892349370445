import getDemographicInfo from './getDemographicInfo';

export default async function gtm(userContext) {
  window.dataLayer = window.dataLayer || [];
  let currentPlanCategory = 'visitor';
  let primaryUse = 'none';
  let secondaryUse = 'none';
  let hasFacebookProfile;
  let hasSignedUpViaFacebook;

  if (userContext) {
    const {
      currentPlan,
      demographics,
      facebookProfile,
      id,
      signedUpViaFacebook
    } = userContext;

    const {
      currentUserPrimaryUse,
      currentUserSecondaryUse,
      segment
    } = getDemographicInfo(demographics);

    currentPlanCategory = currentPlan;
    primaryUse = currentUserPrimaryUse;
    secondaryUse = currentUserSecondaryUse;
    hasFacebookProfile = facebookProfile;
    hasSignedUpViaFacebook = signedUpViaFacebook;

    const idString = id.toString();

    window.dataLayer.push({
      event   : 'setUser',
      user_id : idString,
      user    : {
        schema : 'iglu:com.animoto/user/jsonschema/1-0-0',
        data   : {
          id   : idString,
          plan : currentPlanCategory,
          segment
        }
      }
    });
  }

  window.dataLayer.push({
    dimension1 : `${currentPlanCategory}:${primaryUse}:${secondaryUse}:${parseFloat(window.devicePixelRatio).toFixed(2)}x`,
    dimension2 : 'XX',
    dimension4 : `${hasFacebookProfile ? 'connect' : 'x'}:${hasSignedUpViaFacebook ? 'signup' : 'x'}`,
    user_plan  : currentPlanCategory,
    event      : 'snowplowTrackingReady'
  });
}
