import setUserContext from '@animoto/utils/setUserContext';

/**
 * Grabs the user context (object of user info needed for tracking/launch darkly) from local storage
 * If it doesn't exist, fetch it.
 *
 * @returns {Object} User context
 */
export default async function getUserContext() {
  let userContext = JSON.parse(localStorage.getItem('__uc'));

  // If there's no context in local storage, set context in local storage
  if (!userContext) {
    try {
      userContext = await setUserContext();
    } catch (err) {
      // TODO: when we have sentry set up, log error to sentry
      console.log(err);
    }
  }

  return userContext;
}
