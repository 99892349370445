import initLaunchDarkly from '@animoto/ld-utils/initialize';
import { trackClicks } from '@animoto/utils/analytics';

import getUserContext from './src/utils/getUserContext';
import gtm from './src/utils/gtm';
import { injectInstagramEmbedScript } from './src/utils/vendor-scripts/injectInstagramEmbedScript';

const onClientEntry = async () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer'); // eslint-disable-line global-require
  }
  const userContext = await getUserContext();
  await initLaunchDarkly({
    userOkey    : userContext && userContext.okey,
    environment : process.env.GATSBY_ACTIVE_ENV
  });
  gtm(userContext);
  trackClicks();
};

const onRouteUpdate = () => {
  injectInstagramEmbedScript();
};

const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location);
  const excludedPaths = location.pathname.startsWith('/careers') || location.pathname.startsWith('/blog');

  if (!excludedPaths) {
    window.scrollTo(...(currentPosition || [0, 0]));
  }

  return false;
};

export {
  onClientEntry,
  onRouteUpdate,
  shouldUpdateScroll
};
