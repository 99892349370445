let injectedInstagramScript = false;

function injectScript() {
  const s = document.createElement('script');

  s.type = 'text/javascript';
  s.async = true;
  s.src = '//www.instagram.com/embed.js';
  s.onLoad = () => window.instgrm.Embeds.process();
  document.getElementsByTagName('head')[0].appendChild(s);
}

exports.injectInstagramEmbedScript = function() {
  // If there's an embedded instagram post, lazy-load the instagram script (if it hasn't
  // already been loaded), and then run the instagram process function.
  if (document.querySelector('.instagram-media') !== null || document.querySelector('.babs-instagram-media') !== null) {
    if (!injectedInstagramScript) {
      injectScript();
      injectedInstagramScript = true;
    }

    if (
      typeof instgrm !== 'undefined'
      && window.instgrm.Embeds
      && typeof window.instgrm.Embeds.process === 'function'
    ) {
      window.instgrm.Embeds.process();
    }
  }
};
